import React from 'react'
import { motion } from 'framer-motion'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
import styled from 'styled-components'

const transition2 = {
  duration: 0.6,
  ease: [0.43, 0.13, 0.23, 0.96],

  when: 'afterChildren',
}
const mainVariants = {
  initial: { opacity: 0 },
  enter: isProjects => ({
    opacity: 1,
    transition: { delay: isProjects ? 0.5 : 0, ...transition2 },
  }),
  exit: {
    opacity: 0,
    transition: {
      transition2,
      delay: 1,
    },
  },
}

const TransitionContext = ({ children, location }) => {
  const state = useTransitionState()
  const isProjects = location.pathname === '/projects'
  return (
    <>
      <TransitionWrapper
        key={location.pathname}
        variants={mainVariants}
        custom={isProjects}
        initial={false}
        animate={
          ['entering', 'entered'].includes(state.transitionStatus)
            ? 'enter'
            : 'exit'
        }
        exit="exit"
      >
        {children}
      </TransitionWrapper>
    </>
  )
}

export default TransitionContext

const TransitionWrapper = styled(motion.div)`
  width: 100% !important;
  height: 100% !important;
  min-height: 100vh;
  min-width: 100vw;
  section {
    width: 100%;
    height: 100%;
  }
`
