import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        social {
          username
        }
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        defaultSocialUsername: twitterUsername
      }
    }
  }
`

function SEO({ description, lang, meta, image: metaImage, title }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const { siteUrl, defaultImage } = site.siteMetadata
  const metaDescription = description || site.siteMetadata.defaultDescription
  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : `${site.siteMetadata.siteUrl}${defaultImage}`
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  const schema = {
    '@context': `https://schema.org`,
    '@type': `Person`,
    name: `${site.siteMetadata.title}`,
    alternateName: `A Hoag Media`,
    description: `${
      site.siteMetadata.description || site.siteMetadata.description
    }`,
    url: `${site.siteMetadata.siteUrl}`,
    logo: ``,
    contactPoint: {
      '@type': `ContactPoint`,
      telephone: `910-616-2727`,
      contactType: `sales`,
      areaServed: `US`,
      availableLanguage: `en`,
    },
    sameAs: [
      `https://www.instagram.com/ahoagmedia`,
      `https://www.twitter.com/ahoagmedia/`,
      `https://www.linkedin.com/in/ahoagmedia/`,
      `https://www.vimeo.com/ahoagmedia/`,
    ],
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={site.siteMetadata.titleTemplate}
      link={
        canonical
          ? [
              {
                rel: 'canonical',
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },

        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          name: `twitter:creator`,
          content: `@ahoagmedia`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: `og:image`,
                  content: image,
                },

                {
                  property: `og:image:width`,
                  content: metaImage.width,
                },
                {
                  property: `og:image:height`,
                  content: metaImage.height,
                },

                {
                  name: `twitter:card`,
                  content: `summary_large_image`,
                },
              ]
            : [
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
              ]
        )
        .concat(meta)}
    >
      {schema && (
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  pathname: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  pathname: PropTypes.string,
}

export default SEO
